/*
 * A view model for the Leaderboard component
 */

import textColor from './textColor.es6.js';

class LeaderboardViewModel {
  constructor({
    holes, // [Hole] - list of holes of the first tee to be used as the table header (Desktop)
    holesPerTee, // Object - list of holes, per tee, to be used as the header, for each player, in mobile
    scoreTitle, // String - title of the score column
    toParTitle, // String - title of the to par column
    entries, // [RoundEntry] - a list of rounds/users to be presented on this leaderboard
    users, // [User] - a list of all the players
  }) {
    this.holes = holes;
    this.holesPerTee = holesPerTee;
    this.scoreTitle = scoreTitle;
    this.toParTitle = toParTitle;
    this.entries = entries;
    this.users = users;
  }
}

class Hole {
  constructor({
    sequence,
    par,
    strokeIndex,
  }) {
    this.sequence = sequence;
    this.par = par;
    this.strokeIndex = strokeIndex;
  }
}

class RoundEntry {
  constructor({
    key, // String - a key to diferentiate each UI element on React model
    rank, // Int - rank of the user/round on the leadeboard
    user, // User
    tee, // Tee - the tee selected for this round
    holes, // [HoleScore]
    totalScore, // Int - the current/final score
    toParScore, // Int - the current/final to par score
    throughHole, // Int - the number of played holes
  }) {
    this.key = key;
    this.rank = rank;
    this.user = user;
    this.tee = tee;
    this.holes = holes;
    this.totalScore = totalScore;
    this.toParScore = toParScore;
    this.throughHole = throughHole;
  }
}

class HoleScore {
  constructor({
    isScratched,
    score,
    subScore, // value to be displayed next to the score (usually net-score)
    scoreColorName,
  }) {
    this.isScratched = isScratched;
    this.score = score;
    this.subScore = subScore;
    this.scoreColorName = scoreColorName;
  }
}

class User {
  constructor({
    fullName,
    subtitle,
    avatarUrl,
    tee,
  }) {
    this.fullName = fullName;
    this.subtitle = subtitle;
    this.avatarUrl = avatarUrl;
    this.tee = tee;
  }
}

class Tee {
  constructor({
    name,
    backgroundColor,
  }) {
    this.name = name;
    this.backgroundColor = backgroundColor;
    this.textColor = textColor(backgroundColor);
  }
}

function paramsByScoringMode(value) {
  switch (value) {
    case 'stroke_play_gross':
      return {
        scoreTitle: 'Stk',
        totalScoreParam: 'gross_score_stroke_play',
        subScoreParam: undefined,
        toParTitle: 'To Par',
        toParParam: 'to_par_gross_score',
      };
    case 'stroke_play_net':
      return {
        scoreTitle: 'Stk',
        totalScoreParam: 'net_score_stroke_play',
        subScoreParam: 'net_score_stroke_play',
        toParTitle: 'To Par (Net)',
        toParParam: 'to_par_net_score',
      };
    case 'stableford_gross':
      return {
        scoreTitle: 'Pts',
        totalScoreParam: 'gross_score_stableford',
        subScoreParam: 'gross_score_stableford',
        toParTitle: 'To Par',
        toParParam: 'to_par_points_gross_score',
      };
    case 'stableford_net':
      return {
        scoreTitle: 'Pts',
        totalScoreParam: 'net_score_stableford',
        subScoreParam: 'net_score_stableford',
        toParTitle: 'To Par (Net)',
        toParParam: 'to_par_points_net_score',
      };
    default:
      return paramsByScoringMode('gross_score_stroke_play');
  }
}

function toParString(value) {
  if (value === undefined) {
    return undefined;
  } else if (value > 0) {
    return `+${value}`;
  } else if (value === 0) {
    return 'E';
  }
  return `${value}`;
}

function toParClassName(value) {
  if (value === undefined) {
    return undefined;
  } else if (value >= 2) {
    return 'bogey2';
  } else if (value === 1) {
    return 'bogey';
  } else if (value === 0) {
    return 'par';
  } else if (value === -1) {
    return 'birdie';
  }
  return 'eagle';
}

function numberToHandicap(value) {
  if (value && value < 0) {
    return `+${Math.abs(value)}`;
  }

  return value;
}

/*
 * A method for creating a LeaderboardViewModel from the server response
 */

function createLeaderboardFromApi(leaderboard, scoringMode, defaultAvatar) {
  // This is the list of holes that will be displayed on the table header in desktop version.
  // Since, by design, we only have one header with hole data, we're defaulting to the first tee and show
  // par and S.I. of that tee for each hole.
  const holes = leaderboard.tees[0].holes.map((hole) => new Hole({
    sequence: hole.sequence,
    par: hole.par,
    strokeIndex: hole.stroke_index,
  }));

  // This is the list of holes that will be displayed on the table header in mobile version.
  // In mobile our design shows the hole data for each round, so we can leverage that and show
  // the correct values (par and S.I.) for each round, according to its tee.
  const holesPerTee = {};

  leaderboard.tees.forEach((tee) => {
    if (!tee.holes || tee.holes.length === 0) {
      holesPerTee[tee.name] = [];
      return;
    }

    holesPerTee[tee.name] = tee.holes.map((hole) => {
      return new Hole({
        sequence: hole.sequence,
        par: hole.par,
        strokeIndex: hole.stroke_index,
      });
    });
  });

  // Parse score title and values for this scoring mode
  const { scoreTitle, totalScoreParam, subScoreParam, toParTitle, toParParam } = paramsByScoringMode(scoringMode);

  // Parse Entries
  const entries = leaderboard.entries.map((entry, index) => {
    const entryKey = `${index}+${entry.user.full_name}`;
    let userSubtitle = entry.user.handicap ? `(HCP ${numberToHandicap(entry.user.handicap)})` : '(no HCP)';
    let holeScores = [];
    let totalScore;
    let toParScore;
    let throughHole;
    let tee;

    if (entry.round) {
      userSubtitle = `P.HCP ${numberToHandicap(entry.round.playing_handicap)} (HCP ${numberToHandicap(entry.round.handicap)})`;

      let totalPlayedHoles = 0;

      holeScores = entry.round.scorecard.map((hole) => {
        const toPar = hole.to_par_gross_score;
        const scoreColorName = toParClassName(toPar) || 'none';

        if (hole.total_of_strokes && hole.total_of_strokes > 0) {
          totalPlayedHoles += 1;
        }

        return new HoleScore({
          isScratched: hole.total_of_strokes && hole.scratched,
          score: hole.total_of_strokes,
          subScore: hole[subScoreParam],
          scoreColorName,
        });
      });

      entry.round.to_par_points_gross_score = totalPlayedHoles * 2 - entry.round.gross_score_stableford;
      entry.round.to_par_points_net_score = totalPlayedHoles * 2 - entry.round.net_score_stableford;

      totalScore = entry.round[totalScoreParam];
      toParScore = toParString(entry.round[toParParam]);

      if (totalPlayedHoles <= 0) {
        throughHole = '-';
      } else if (totalPlayedHoles === holes.length) {
        throughHole = 'F';
      } else {
        throughHole = totalPlayedHoles;
      }

      tee = new Tee({
        name: entry.round.tee.name,
        backgroundColor: entry.round.tee.rgb,
      });
    }

    const user = new User({
      fullName: entry.user.full_name,
      subtitle: userSubtitle,
      avatarUrl: entry.user.avatar_url || defaultAvatar,
      tee,
    });

    return new RoundEntry({
      key: entryKey,
      rank: index + 1,
      user,
      tee,
      holes: holeScores,
      totalScore,
      toParScore,
      throughHole,
    });
  });

  const users = entries.map(entry => (
    new User({
      fullName: entry.user.fullName,
      subtitle: entry.user.subtitle,
      avatarUrl: entry.user.avatarUrl,
      tee: entry.user.tee,
    })
  ));

  return new LeaderboardViewModel({
    users,
    holes,
    holesPerTee,
    scoreTitle,
    toParTitle,
    entries,
  });
}

export default createLeaderboardFromApi;
