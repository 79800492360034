// Entry point for the build script in your package.json

import * as React from "react";
import * as ReactDOM from "react-dom";

import "./jquery.js";
import "./base.js"

// Global API helpers
import { Get, Post, Put } from "./helpers/networking.es6";
window.Get = Get;
window.Post = Post;
window.Put = Put;

/**
 * --------------------------
 * React components
 * --------------------------
 */
import Alert from "./components/Alert.es6";
import CommonHeader from "./components/CommonHeader.es6";
import Company from "./components/Company.es6";
import Countries from "./components/courses/Countries.es6";
import CourseIdentityProfile from "./components/courses/profile/CourseProfile.es6";
import CuratedListCourses from "./components/curatedLists/Show.es6";
import DiscoverCourses from "./components/DiscoverCourses.es6";
import EmailUnsubscribe from "./components/preferences/email/EmailUnsubscribe.es6.jsx";
import ErrorScreen from "./components/ErrorPage.es6";
import Home from "./components/Home.es6";
import ImportMultiplayerRound from "./components/performance/rounds/ImportMultiplayerRound.es6";
import NotificationPreferences from "./components/preferences/notifications/NotificationPreferences.es6.jsx";
import PremiumOffer from "./components/PremiumOffer.es6.jsx";
import Regions from "./components/courses/Regions.es6";
import RoundLeaderboardsIndex from "./components/performance/leaderboards/Index.es6";
import RoundLeaderboardsShow from "./components/performance/leaderboards/Show.es6";
import ShowSubscription from "./components/premiumSubscription/ShowSubscription.es6";
import StoreButtons from "./components/StoreButtons.es6.jsx";

function mount(components) {
  document.addEventListener("DOMContentLoaded", () => {
    const mountPoints = document.querySelectorAll("[data-react-component]");
    mountPoints.forEach((mountPoint) => {
      const { dataset } = mountPoint;
      const componentName = dataset.reactComponent;
      if (componentName) {
        const Component = components[componentName];
        if (Component) {
          const props = JSON.parse(dataset.props);
          ReactDOM.render(<Component {...props} />, mountPoint);
        } else {
          console.warn("WARNING: No component found for: ", dataset.reactComponent, components);
        }
      }
    });
  });
};

mount({
  Alert,
  CommonHeader,
  Company,
  Countries,
  CourseIdentityProfile,
  CuratedListCourses,
  DiscoverCourses,
  EmailUnsubscribe,
  ErrorScreen,
  Home,
  ImportMultiplayerRound,
  NotificationPreferences,
  PremiumOffer,
  Regions,
  RoundLeaderboardsIndex,
  RoundLeaderboardsShow,
  ShowSubscription,
  StoreButtons,
});
