import React from 'react';

/*
 * Displays a list of users who joined the leaderboard and their respective round scores
 */

const Leaderboard = ({ model, holesPerTee }) => (
  <section className="leaderboard">
    <div className="container">
      <ol className="players">
        {model.entries.map((entry) =>
          <LeaderboardEntry key={entry.key} model={model} entry={entry} holesList={holesList(entry, holesPerTee)} />)}
      </ol>
    </div>
  </section>
);

/**
 * Return the holes list if the player has a round (and selected tee)
 */
const holesList = (entry, holesPerTee) => {
  let holesList = [];

  if (entry.tee) {
    holesList = holesPerTee[entry.tee.name];
  }

  return holesList;
}

class LeaderboardEntry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cellOpen: false,
      nameExpanded: false,
    };
  }

  toggleState = key => () => { this.setState({ [key]: !this.state[key] }); };

  render() {
    const { model, entry, holesList } = this.props;
    const tee = entry.tee;

    return (
      <li className={`player-cell ${!this.state.cellOpen ? 'closed' : null}`} onClick={this.toggleState('cellOpen')}>
        <div className="details">
          <p className="rank caption-1-bold">{entry.rank}</p>

          <div className="avatar-container">
            <img className="avatar-40 round" role="presentation" src={entry.user.avatarUrl} alt={entry.user.fullName} />

            {Optional(tee).map(() => (
              <p className="player-tee text-upper" style={{ backgroundColor: tee.backgroundColor, color: tee.textColor }}>{tee.name}</p>
            ))}
          </div>

          <div className="player-info">
            <p className={`name body-bold ${entry.user.fullName.length > 10 ? 'large-name' : ''}`}>{entry.user.fullName}</p>

            {entry.user.fullName.length > 10 &&
              <div className={`expand-name ${this.state.nameExpanded ? 'visible' : null}`}>
                <span className="action" onClick={this.toggleState('nameExpanded')}></span>
                <p className="tooltip caption-1-bold" onClick={this.toggleState('nameExpanded')}>{entry.user.fullName}</p>
              </div>
            }

            <p className="handicap subhead text-light">{entry.user.subtitle}</p>
          </div>
        </div>

        <div className="score">
          {entry.holes.length > 0 &&
            <div className="scrollable">
              <div className="holes-details">
                <div className="holes">
                  <p className="caption-2-bold text-light text-upper">Holes</p>

                  <div className={`holes-list ${holesList.length}`}>
                    {holesList.map((hole) => <p key={hole.sequence} className="hole caption-2-bold text-light">{hole.sequence}</p>)}
                  </div>
                </div>

                <div className="par">
                  <p className="caption-2-bold text-light text-upper">PAR</p>

                  <div className={`par-list ${holesList.length}`}>
                    {holesList.map((hole) => <p key={hole.sequence} className="hole caption-2 text-lighter">{hole.par}</p>)}
                  </div>
                </div>

                <div className="si">
                  <p className="caption-2-bold text-light text-upper">S.I.</p>

                  <div className={`si-list ${holesList.length}`}>
                    {holesList.map((hole) => <p key={hole.sequence} className="hole caption-2 text-lighter">{hole.strokeIndex}</p>)}
                  </div>
                </div>
              </div>

              <div className="holes-scores">
                <p className="section-label caption-2-bold text-light text-upper">
                  {entry.holes.length > 0 && 'Score'}
                </p>

                <div className="scores">
                  {entry.holes.map((hole, index) =>
                    <div key={index} className={`hole-score score-${hole.scoreColorName}`}>
                      <p className={`value body-bold ${Optional(hole.isScratched).map(() => 'scratched')}`}>
                        {hole.score || '–'}
                      </p>

                      <span className="hole-points caption-2 text-light">{hole.subScore}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          }

          <div className="additional">
            {entry.holes.length > 0 &&
              <div className="labels">
                <p className="caption-2-bold text-light">{model.scoreTitle}</p>
                <p className="caption-2-bold text-light" data-content={model.toParTitle.toLowerCase().replace(/\s/g, '-')}><span>{model.toParTitle}</span></p>
                <p className="caption-2-bold text-light">THRU</p>
              </div>
            }

            <div className="values">
              <p className="body-bold">{entry.totalScore}</p>
              <p className="body-bold">{entry.toParScore}</p>
              <p className="body-bold">{entry.throughHole}</p>
            </div>
          </div>
        </div>

        {entry.holes.length > 0 &&
          <button className="expand-cell"></button>
        }
      </li>
    );
  }
}

export default Leaderboard;
